import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { URL_API_FNA } from "../../utils/helper";

const AssessorScreen = () => {
    const navigate = useNavigate();
    const { /* roomId, */ uniqId, na } = useParams();

    const urlAPI = URL_API_FNA;

    const fetchInitNumber = async () => {
        try {
            const data = {
                "uniqId": uniqId
            };

            const response = await fetch(`${urlAPI}/api/v1/petition-checker/initNumber`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
                .then(data => {
                    if (data.status) {
                        return data.json()
                    }
                    throw new Error("Error al obtener información")
                });

            if (response.success) {
                return response.data.sysVideo
            }

        } catch (error) {
            console.log({ error })
        }
    }

    useEffect(() => {

        const fetchData = async () => {
            try {

                const sysVideo = await fetchInitNumber();
                if (sysVideo === undefined) {
                    navigate(`/leave`, { replace: true, state: { isAssessorScreen: true, expiredRoom: true } });
                    return;
                }
                console.log({ sysVideo })
                const bodyRequest = {
                    "uniqId": uniqId
                };

                let response = await fetch(`${urlAPI}/api/v1/petition-checker/findRoom`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(bodyRequest),
                })
                    .then(data => {
                        if (data.status) {
                            return data.json()
                        }
                        throw new Error("Error al obtener información")
                    })

                response = { ...response, source: 'asesorScreen', assessorName: na, dataSysVideo: sysVideo };
                navigate(`/`, { replace: true, state: response });

            } catch (error) {
                console.error('Error al hacer la petición:', error);
            }

        };

        fetchData();
    }, []
    );

    return (
        <div
            className="text-white dark:bg-meeting"
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
            }}
        >
            <p className="hidden">Pruebas pantalla de asesor</p>
        </div>
    );
};

export default AssessorScreen;
