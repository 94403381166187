import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function LeaveScreen({ setIsMeetingLeft }) {
  const location = useLocation();
  const [showMessage, setShowMessage] = useState(false);
  const { tokenVideoSDK, isAssessorScreen, dataSessionRoom, expiredRoom = false } = location.state || {};

  return (
    <div className={`h-screen flex flex-col flex-1 items-center justify-center bg-meeting`}>
      <h1 className="text-black dark:text-white text-4xl">
        {
          expiredRoom ?
            'La reunión ya ha finalizado'
            :
            'Haz salido de la reunión'
        }
      </h1>
      <div className="mt-12">
      </div>
    </div>
  );
}
